import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const Modal = ({ isOpen, children }) => {

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  let modalRoot = document.getElementById("modal-root");
  if (!modalRoot) {
    modalRoot = document.createElement("div");
    modalRoot.id = "modal-root";
    document.body.appendChild(modalRoot);
  }

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed overflow-y-auto inset-0 bg-black bg-opacity-50 flex  justify-center z-50 h-full">
      {children}
    </div>,
    document.getElementById("modal-root")
  );
};

export default Modal;
