import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../Component/Header";
import Loader from "../Component/shared/Loader";
import { SEND_PASSWORD_RESET_EMAIL } from "../Constants";
import axios from "axios";
import { Notification } from "../module/Notification";

const schema = yup.object({
  email: yup.string().email("Invaild email").required("Email is required"),
});
const ForgetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await axios.post(SEND_PASSWORD_RESET_EMAIL, { ...data });
      Notification.success("Password reset email sent. Please check your inbox.")
    } catch (error) {
      setError("email", { message: "Invalid User! Credentials not found." });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="flex items-center justify-center h-[85vh] bg-gray-100">
        <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-center">Forget Password</h2>
          <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <input
                type="email"
                id="email"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm"
                {...register("email")}
              />
              {errors?.email && (
                <div className="text-red-600 text-xs mt-0.5">
                  {errors.email.message}
                </div>
              )}
            </div>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {isLoading ? <Loader /> : "Submit"}
            </button>
            <p className="text-sm text-center text-gray-600">
              Back to{" "}
              <Link to="/signin" className="text-indigo-600 hover:underline">
                Sign In
              </Link>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
