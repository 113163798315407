export class CustomerId {
    static get(){
        return localStorage.getItem('customerId') || null
    }

    static set(id){
        localStorage.setItem('customerId', id)
    }

    static clear(){
        localStorage.removeItem('customerId')
    }
}