import { toast } from "react-toastify";

export class Notification{
    static error(message, options){
        toast.error(message , options)
    }

    static warning(message){
        toast.warning(message)
    }

    static success(message){
        toast.success(message)
    }

    static info(message){
        toast.info(message)
    }
}