import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "./shared/Modal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { BUY_SUBSCRIPTION } from "../Constants";
import { CustomerId } from "../module/CustomerId";
import { Token } from "../module/token";
import { toast } from "react-toastify";

const schema = yup.object({
  transactionId: yup.string().required("Transaction ID is required"),
  subscriptionPlan: yup.string().required("Please select a subscription Plan"),
  userNote: yup.string(),
});

const SubscriptionModal = ({ isOpen, handleToggle }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const customerId = CustomerId.get();
      const token = Token.get();
      await axios.post(
        `${BUY_SUBSCRIPTION}/${customerId}`,
        {
          ...data,
          id: customerId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Sent Successfully")
    } catch (error) {
      toast.error("Sorry an error occurred!")
    }finally{
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <div
        className="bg-white my-auto relative p-4 rounded-lg gap-5 h-max"
        data-testid="subscription-modal"
      >
        <button
          onClick={handleToggle}
          className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 rounded-full"
          data-testid="modal-close-button"
        >
          <span className="text-3xl relative top-[-3px] font-semibold text-gray-600">
            ×
          </span>
        </button>

        <div className="flex flex-col sm:flex-row justify-evenly items-center gap-5">
          <div>
            <h2 className="text-xl font-bold mb-4">UCO Bank Account Details</h2>
            <div className="space-y-4">
              <p>
                <span className="font-semibold">Account Number:</span>{" "}
                13850210003121
              </p>
              <p>
                <span className="font-semibold">IFSC Code:</span> UCBA0001385
              </p>
              <p>
                <span className="font-semibold">Account Name:</span> KUMAWAT
                KSHATRIYA PATRA TRUST
              </p>
            </div>

            <div className="mt-6">
              <h3 className="font-semibold mb-2">Subscription Options</h3>
              <ul>
                <li>Three years: ₹600/-</li>
                <li>Five years: ₹1000/-</li>
                <li>Twenty years: ₹4100/-</li>
              </ul>
              <div className="p-1 mt-3 border rounded-lg shadow-md w-fit bg-white">
                <p className="text-lg font-semibold text-gray-800">
                  One Copy:{" "}
                  <span className="text-blue-600 font-bold">₹20/-</span>
                </p>
              </div>
            </div>
          </div>

          <div className="hidden sm:flex flex-col items-center">
            <div className="h-16 w-px bg-gray-400"></div>
            <span className="text-gray-500 font-semibold text-lg">OR</span>
            <div className="h-16 w-px bg-gray-400"></div>
          </div>

          <img
            src="/images/QR.png"
            alt="QR Code"
            className="mt-8 h-[300px] w-auto"
          />
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-6 space-y-4">
          <h4 className="text-lg font-semibold text-gray-700">
            After completing the transaction, please submit the form.
          </h4>

          <div>
            <label className="block font-semibold">Transaction ID</label>
            <input
              type="text"
              {...register("transactionId")}
              className="w-full border p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {errors?.transactionId && (
              <div className="text-red-600 text-xs mt-0.5">
                {errors.transactionId.message}
              </div>
            )}
          </div>

          <div>
            <label className="block font-semibold">Subscription Type</label>
            <select
              {...register("subscriptionPlan")}
              className="w-full border p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="" disabled selected>
                Select the chosen plan
              </option>
              <option value="threeYears">Three Years (₹600)</option>
              <option value="fiveYears">Five Years (₹1000)</option>
              <option value="twentyYears">Twenty Years (₹4100)</option>
              <option value="single">Single Copy (₹20)</option>
            </select>
            {errors?.subscriptionPlan && (
              <div className="text-red-600 text-xs mt-0.5">
                {errors.subscriptionPlan.message}
              </div>
            )}
          </div>

          <div>
            <label className="block font-semibold">User Note</label>
            <textarea
              {...register("userNote")}
              className="w-full resize-none border p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700 transition"
            disabled={isLoading}
          >
            {isLoading ? "...Submitting" : "Submit"}
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default SubscriptionModal;
