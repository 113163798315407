import moment from "moment";
import React from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { CustomerId } from "../module/CustomerId";

const PrivateRoute = ({ element }) => {
  const isAuthenticated = CustomerId.get();
  const {customerData} = useAppContext();
  const subscriptionEndDate = moment(customerData?.subscriptionValidTo);
  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }
  if (subscriptionEndDate.isBefore(moment())) {
    return <Navigate to="/" />
  }
  return element;
};

export default PrivateRoute;
