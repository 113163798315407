import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useLocation } from "react-router-dom";
import Loader from "../Component/shared/Loader";
import Header from "../Component/Header";
import axios from "axios";
import { GET_PATRIKA } from "../Constants";

const PdfViewer = () => {
  const location = useLocation();
  const [patrika, setPatrika] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [patrikaLoadingProgress, setPatrikaLoadingProgress] = useState(0);
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const id = new URLSearchParams(location.search).get("id");

  useEffect(() => {
    if (id) {
      const fetchPatrika = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${GET_PATRIKA}/${id}`);
          if (response.data) {
            setPatrika(response.data);
          }
        } catch (error) {
          setError(
            "Error: Patrika not found. Please try reloading or attempt again later."
          );
        } finally {
          setLoading(false);
        }
      };
      fetchPatrika();
    }
  }, [id]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsPdfLoaded(true);
  };

  const onLoadProgress = ({ loaded, total }) => {
    const progress = (loaded / total) * 100;
    setPatrikaLoadingProgress(progress);
  };

  return (
    <div className="flex min-h-screen flex-col">
      <Header />
      <main className="flex-1 overflow-auto">
        <div className="m-6">
          {loading && <Loader />}
          {error && <div className="flex justify-center items-center text-lg font-semibold mt-12">{error}</div>}
          {patrika?.patrikaFile?.length > 0 && (
            <>
              {!isPdfLoaded && (
                <div className="flex gap-2">
                  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 relative">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={{ width: `${patrikaLoadingProgress}%` }}
                    ></div>
                  </div>
                  <div className="relative top-[-6px] text-sm text-gray-700">
                    {Math.round(patrikaLoadingProgress)}%
                  </div>
                </div>
              )}
              <div className="flex justify-center align-center">
                <Document
                  file={patrika.patrikaFile[0].downloadUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadProgress={onLoadProgress}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={index}
                      pageNumber={index + 1}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  ))}
                </Document>
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default PdfViewer;
