import React, { useState } from "react";
import { Link } from "react-router-dom";
import BuySubscription from "./BuySubscription";
import ProfileModal from "./ProfileModal";
import { CustomerId } from "../module/CustomerId";
import { useAppContext } from "../context/AppContext";
import moment from "moment";
const Header = () => {
  const { customerData, handleCustomerData } = useAppContext();
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const isSubscriptionValid = moment(customerData?.subscriptionValidTo).isAfter(moment());
  const customerId = CustomerId.get();

  const handleProfileModalToggle = () => setIsProfileModalOpen((prev) => !prev);

  const handleSignOut = () => {
    CustomerId.clear();
    handleCustomerData(null);
    window.location.reload();
  };

  return (
    <>
      <header className="bg-sky-100 sticky top-0 z-50 bg-background px-4 py-3 shadow-sm md:px-12">
        <div className="container mx-auto flex items-center justify-between">
          <Link to={"/"} className="flex items-center gap-2">
            <img src="/images/logo.jpg" width={45} height={45} alt="logo.jpg" />
            <span className="text-lg font-semibold">
              Kumawat Kshatriya Patra Trust
            </span>
          </Link>
          <div className="flex items-center gap-4">
            {customerId ? (
              <>
                <div>
                  <div
                    onClick={handleProfileModalToggle}
                    className="cursor-pointer"
                    data-testid="profile-name"
                  >
                    {customerData?.name}
                  </div>
                  {!isSubscriptionValid && (
                    <div
                      className="text-xs cursor-pointer"
                      onClick={handleSignOut}
                      data-testid="signout-button"
                    >
                      Sign out
                    </div>
                  )}
                </div>
                {isSubscriptionValid ? (
                  <button
                    className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    onClick={handleSignOut}
                    data-testid="signout-button"
                  >
                    Sign out
                  </button>
                ) : (
                  <BuySubscription />
                )}
              </>
            ) : (
              <>
                <Link
                  to="/signup"
                  className="text-sm font-medium hover:underline hover:underline-offset-4"
                >
                  Subscribe
                </Link>
                <Link
                  to="/signin"
                  className="inline-flex h-9 items-center justify-center rounded-md bg-primary px-4 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
                  data-testid="signin-button"
                >
                  Sign In
                </Link>
              </>
            )}
          </div>
        </div>
      </header>
      {isProfileModalOpen && (
        <ProfileModal
          isOpen={isProfileModalOpen}
          handleToggle={handleProfileModalToggle}
        />
      )}
    </>
  );
};

export default Header;
