import React from "react";
import moment from "moment";
import Modal from "./shared/Modal";
import { useAppContext } from "../context/AppContext";

const ProfilePageModal = ({ isOpen, handleToggle }) => {
  const {customerData} = useAppContext();

  if (!customerData) {
    return null;
  }

  const { name, email, subscriptionValidTo } = customerData;

  const subscriptionDate = subscriptionValidTo ? moment(subscriptionValidTo) : null;
  const isSubscribed = subscriptionDate && subscriptionDate.isAfter(moment());

  return (
    <Modal isOpen={isOpen}>
      <div className="bg-white my-auto relative p-4 rounded-lg gap-5 h-max w-[300px] shadow-lg" data-testid="profile-modal">
        <button
          onClick={handleToggle}
          className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 rounded-full"
          data-testid='modal-close-button'
        >
          <span className="text-3xl relative top-[-3px] font-semibold text-gray-600">
            ×
          </span>
        </button>

        <div className="text-gray-700 p-4">
          <h2 className="text-lg font-semibold">Profile</h2>
          <p><strong>Name:</strong> {name}</p>
          <p><strong>Email:</strong> {email}</p>
          <p><strong>Subscription Status:</strong> {isSubscribed ? "Subscribed" : "Unsubscribed"}</p>
          <p><strong>Valid Until:</strong> {subscriptionDate ? subscriptionDate.format("YYYY-MM-DD HH:mm:ss") : "N/A"}</p>
        </div>
      </div>
    </Modal>
  );
};

export default ProfilePageModal;
