import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import { CustomerId } from "../module/CustomerId";
import { ME } from "../Constants";
import moment from "moment";
import { Notification } from "../module/Notification";

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const customerId = CustomerId.get();
  const [customerData, setCustomerData] = useState(null);

  const fetchUser = async (customerId) => {
    const response = await axios.get(`${ME}/${customerId}`);
    const userData = response.data;
    setCustomerData(response.data);

    const subscriptionEndDate = moment(userData?.subscriptionValidTo);
    const daysRemaining = subscriptionEndDate.diff(moment(), "days");

    if (daysRemaining <= 7 && daysRemaining > 0) {
      const message = `Your subscription is expiring in ${daysRemaining} day${
        daysRemaining === 1 ? "" : "s"
      }!`;
      Notification.warning(message);
    }

    if (
      Boolean(userData?.subscriptionValidTo) &&
      subscriptionEndDate.isBefore(moment())
    ) {
      Notification.error("Your subscription has ended. Please renew!", {
        autoClose: false,
      });
    }

    if (userData?.status === "pending") {
      Notification.info("Your account will be activated within 3-4 days");
    }
  };

  const handleCustomerData = (data) => {
    setCustomerData(data);
  };

  useEffect(() => {
    if (customerId) {
      fetchUser(customerId);
    }
  }, [customerId]);

  return (
    <AppContext.Provider value={{ customerData, handleCustomerData }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
