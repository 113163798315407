export class Token {
    static get(){
        return localStorage.getItem('token') || null
    }

    static set(token){
        localStorage.setItem('token', token)
    }

    static clear(){
        localStorage.removeItem('token')
    }
}