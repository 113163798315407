import { Link } from "react-router-dom";
import Header from "../Component/Header";
import ShortSubscribeForm from "../Component/ShortSubscribeForm";
import PatrikaList from "../Component/PatrikaList";
import { CustomerId } from "../module/CustomerId";

export default function Dashboard() {
  const customerId = CustomerId.get();

  return (
    <div className="flex min-h-[100dvh] flex-col">
      <Header />
      <main className="flex-1">
        <section className="bg-muted py-12 md:py-24 lg:py-32 px-4 md:px-12">
          <div className="container w-full mx-auto">
            <div className="grid gap-6 lg:grid-cols-2 lg:gap-12 items-center">
              <img
                src="/images/image1.jpg"
                alt="Latest Issue"
                className="mx-auto overflow-hidden rounded-xl sm:w-full lg:order-last h-[210px]"
              />
              <div className="flex flex-col justify-center space-y-6">
                <div className="space-y-4">
                  <h1 className="text-3xl font-bold tracking-tighter sm:text-xl xl:text-3xl/none">
                    Purpose of publication of magazine
                  </h1>
                  <p className="max-w-[600px] text-muted-foreground md:text-xl">
                    The purpose of the publication is social and cultural
                    upliftment of Kumawat Samaj. In this magazine we publish
                    socially useful information /Article, details of
                    marriageable boys & girls, advertisements related to
                    business enhancement etc. We also invite stories, poems,
                    Articles, social news etc. for the welfare of Kumawat
                    Society but the article should be original and should not be
                    published earlier in any magazine.
                  </p>
                </div>
                <div className="w-full max-w-sm space-y-2">
                  {!customerId && <ShortSubscribeForm />}
                  <p className="text-xs text-muted-foreground">
                    Subscribe to get notified when a new issue is published.{" "}
                    <Link href="#" className="underline underline-offset-2">
                      Terms &amp; Conditions
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container mx-auto py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <div className="inline-block rounded-lg bg-muted px-3 py-1 text-sm">
                  Subscription Benefits
                </div>
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                  Why Subscribe to Our Magazine?
                </h2>
                <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  Unlock exclusive content, stay informed, and support local
                  journalism. Subscribe to our magazine today and enjoy a range
                  of benefits.
                </p>
              </div>
            </div>
            <div className="mx-auto grid max-w-5xl items-center gap-6 py-12 lg:grid-cols-2 lg:gap-12">
              <div className="flex flex-col justify-center space-y-4">
                <ul className="grid gap-6">
                  <li>
                    <div className="grid gap-1">
                      <h3 className="text-xl font-bold">Exclusive Content</h3>
                      <p className="text-muted-foreground">
                        Access in-depth articles, interviews, and
                        behind-the-scenes stories not available elsewhere.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="grid gap-1">
                      <h3 className="text-xl font-bold">Early Access</h3>
                      <p className="text-muted-foreground">
                        Be the first to read the latest issue before it's
                        available to the public.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="grid gap-1">
                      <h3 className="text-xl font-bold">
                        Digital & Print Editions
                      </h3>
                      <p className="text-muted-foreground">
                        Receive both the digital and print versions of the
                        magazine.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <img
                src="/placeholder.svg"
                height="310"
                alt="Subscription Benefits"
                className="mx-auto aspect-video overflow-hidden rounded-xl object-cover object-center sm:w-full lg:order-last"
              />
            </div>
          </div>
        </section>
        <section className="container mx-auto py-12 md:py-24 lg:py-32 bg-muted">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <div className="inline-block rounded-lg bg-muted px-3 py-1 text-sm">
                  Past Issues
                </div>
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                  Download Past Issues
                </h2>
                <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  Explore our archive of past issues and download the ones you
                  missed. Stay up-to-date with the latest news and insights from
                  our local magazine.
                </p>
              </div>
            </div>
            <PatrikaList />
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-muted-foreground">
          &copy; 2024 Local Magazine. All rights reserved.
        </p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <Link
            href="#"
            className="text-xs hover:underline hover:underline-offset-4"
          >
            Terms of Service
          </Link>
          <Link
            href="#"
            className="text-xs hover:underline hover:underline-offset-4"
          >
            Privacy
          </Link>
        </nav>
      </footer>
    </div>
  );
}
