import React, { useState } from "react";
import SubscriptionModal from "./SubscriptionQrModal";

const BuySubscription = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleToggle = () => setIsModalOpen((prev) => !prev);    
  
  return (
    <>
      <button
        className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        onClick={() => handleToggle()}
        data-testid='subscribe-button'
      >
        Subscribe Now
      </button>
      <SubscriptionModal isOpen={isModalOpen} handleToggle={handleToggle} />
    </>
  );
};

export default BuySubscription;
