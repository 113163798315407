import "./App.css";
import Dashboard from "./Pages/Dashboard";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignIn from "./Pages/Signin";
import SignUp from "./Pages/Signup";
import PdfView from "./Pages/PdfViewer";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./Component/PrivateRoute";
import ForgetPassword from "./Pages/ForgetPassword";
import { AppContextProvider } from "./context/AppContext";

function App() {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          transition={Bounce}
        />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/pdf-viewer"
            element={<PrivateRoute element={<PdfView />} />}
          />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;
